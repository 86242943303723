import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';
import ContactTeaser from '../components/ContactTeaser/ContactTeaser';
import IconPhone from '@iconscout/react-unicons/icons/uil-phone';
import IconEnvelope from '@iconscout/react-unicons/icons/uil-envelope';

const Kontakt = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        title="Kontakt"
        location={location}
        meta={[{ name: 'robots', content: 'noindex, nofollow' }]}
      />
      <header className="ContentHeader">
        <div className="inner-wrapper">
          <h1 itemProp="headline">Kontakt</h1>
        </div>
      </header>
      <Section>
        <div>
          <strong>Silja Tyllilä</strong>
          <br />
          <IconPhone className={'Footer__icon'} />
          <a className="Link" href="tel:+4915120150269">
            0151.20.15.02.69
          </a>
          <br />
          <IconEnvelope className={'Footer__icon'} />
          <a className="Link" href="mailto:mail@siljaty.com">
            mail@siljaty.com
          </a>
        </div>
      </Section>
      <Section>
        <ContactTeaser />
      </Section>
    </Layout>
  );
};

export default Kontakt;
